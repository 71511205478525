import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouteConstant} from "@constant/route.constant";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzWaveModule} from "ng-zorro-antd/core/wave";
import {Router} from "@angular/router";
import {genRouterLink} from "@util/route";
import {RightsService} from "@service/auth/rights.service";
import {INav} from "@core/interface/i-nav";
import {HeaderNotificationComponent} from "@core/component/header-notification/header-notification.component";

@Component({
    selector: 'app-mobile-menu-drawer',
    standalone: true,
    imports: [CommonModule, NzAvatarModule, NzButtonModule, NzDividerModule, NzDrawerModule, NzIconModule, NzWaveModule, HeaderNotificationComponent],
    templateUrl: './mobile-menu-drawer.component.html',
    styleUrls: ['./mobile-menu-drawer.component.less']
})
export class MobileMenuDrawerComponent implements OnInit {

    @Input() drawerVisible: boolean = false;
    @Input() isAuthenticated: boolean = false;
    @Input() clientName: string = '';
    @Input() mobilePostNavData: Array<INav> = [];

    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() logoutEvent: EventEmitter<any> = new EventEmitter<any>();

    protected readonly RouteConstant = RouteConstant;


    constructor(
        private router: Router,
        private rightsService: RightsService,
    ) {}

    ngOnInit(): void {
    }

    redirectToDestination(url: string) {
        this.onClose();
        setTimeout(() => {
            this.router.navigate([genRouterLink(url)]).then(() => {});
        }, 301);
    }

    hasAccess(access: string[]) {
        return this.rightsService.checkRights(access);
    }

    onClose() {
        this.closeDrawer.emit(false);
    }

    logout() {
        this.onClose();
        this.logoutEvent.emit();
    }
}
