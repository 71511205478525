<ng-container *ngIf="!(isMobile$ | async)">
    <div nz-dropdown
         nzTrigger="hover"
         [nzDropdownMenu]="dropdownTpl"
         (nzVisibleChange)="onDropdownVisibleChange($event)"
         nzOverlayClassName="header-notification-overlay"
    >
        <ng-container *ngTemplateOutlet="iconTpl"></ng-container>
    </div>
</ng-container>
<ng-container *ngIf="(isMobile$ | async)">
    <ng-container *ngTemplateOutlet="iconTpl"></ng-container>
</ng-container>

<ng-template #iconTpl>
    <div class="relative flex">
        <div *ngIf="headerService.hasUnreadNotification" class="absolute top-0 right-0 z-1 red-dot small"></div>
        <span nz-icon nzType="bell" nzTheme="outline" [routerLink]="genRouterLink(RouteConstant.NOTIFICATION)" class="cursor-pointer hover:text-primary text-[20px] min-w-[20px]"></span>
    </div>
</ng-template>

<nz-dropdown-menu #dropdownTpl="nzDropdownMenu">
    <div class="dropdown" nz-menu>
        <div class="header">
            <h5 class="font-bold mb-0">Notification</h5>
        </div>
        <nz-divider class="my-0"></nz-divider>
        <div class="header">
            <p class="font-bold mb-0 text-sm uppercase text-black-6">TODAY</p>
        </div>
        <div class="mb-5">
            <nz-spin [nzSpinning]="notificationLoading">
                <ng-container *ngIf="notificationList?.length; else emptyTpl">
                    <ng-container *ngFor="let notification of notificationList">
                        <div class="hover:bg-light-primary flex items-center header cursor-pointer" [routerLink]="genRouterLink(RouteConstant.NOTIFICATION)">
                            <div class="flex items-center gap-6">
                                <app-notification-type [type]="notification.type"></app-notification-type>
                                <div>
                                    <p class="font-semibold">{{ notification.message }}</p>
                                    <p class="mb-0 text-xs text-black-6">{{ notification.timeAgo }}</p>
                                </div>
                            </div>
                            <ng-container *ngIf="!notification.read">
                                <ng-container *ngTemplateOutlet="redDotTpl"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </nz-spin>
        </div>
    </div>
</nz-dropdown-menu>

<ng-template #emptyTpl>
    <app-ui-empty-box design="3" title="No recent notification"></app-ui-empty-box>
</ng-template>

<ng-template #redDotTpl>
    <div class="red-dot"></div>
</ng-template>
