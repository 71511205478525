<nz-affix nzOffsetTop="0" class="header-affix print:hidden">
    <app-web-scaffold>
        <div class="bg-white shadow-sm">
            <div class="container">
                <nz-row>
                    <nz-col [nzMd]="6">
                        <a (click)="redirectToDestination('/')">
                            <img src="assets/images/ifm-logo@2x.png" alt="iFast Fund Management" class="header-logo"/>
                        </a>
                    </nz-col>
                    <nz-col [nzMd]="18">
                        <ng-container>
                            <ng-container [ngTemplateOutlet]="postLoginMenuTpl"></ng-container>
                        </ng-container>
                    </nz-col>
                </nz-row>
            </div>
        </div>
    </app-web-scaffold>
    <app-mobile-scaffold>
        <div class="mobile-header-container">
            <a [routerLink]="['/']">
                <img src="assets/images/ifm-logo@2x.png" alt="iFast Fund Management Logo" class="mobile-header-logo"/>
            </a>
            <div>
                <button nz-button nzType="primary" (click)="showDrawer()"
                        class="ant-btn-sm">
                    <i nz-icon nzType="menu" nzTheme="outline"></i>
                </button>
            </div>
        </div>
    </app-mobile-scaffold>
</nz-affix>

<ng-template #postLoginMenuTpl>
    <ul class="header-desktop-nav items-center" nz-menu nzTheme="light" nzMode="horizontal">
        <ng-container *ngFor="let item of postNavData">
            <ng-container *ngIf="hasAccess(item.right)">
                <li *ngIf="!item?.children" nz-menu-item nzMatchRouter="true" routerLinkActive="active">
                    <a [routerLink]=[item?.url]>
                        <ng-container *ngTemplateOutlet="titleTpl"></ng-container>
                    </a>
                </li>
                <li *ngIf="item?.children" nz-submenu [nzTitle]="titleTpl"
                    [ngClass]="{'selected-header-route': isCurrentRoute(item?.url) }"
                    [nzPlacement]="item?.menuPlacementRight? 'bottomRight' : 'bottomLeft'"
                    nzMenuClassName="header-desktop-submenu-cdk"
                    routerLinkActive="active">
                    <ul *ngIf="item?.children">
                        <ng-container *ngFor="let child of item?.children">
                            <ng-container *ngIf="hasAccess(child.right)">
                                <li nz-menu-item routerLinkActive="active"
                                    [class.child-active]="child.children && showChildrenRoute"
                                    [class.has-child-of-child]="child.children"
                                    (click)="checkForLogout(child?.title)"
                                >
                                    <ng-template
                                        *ngTemplateOutlet="childTitleTpl; context: { child: child}"></ng-template>
                                </li>
                                <ul (mouseover)="showChildrenRoute = true"
                                    (mouseout)="showChildrenRoute = false"
                                    *ngIf="child.children " class="child-of-child-wrapper">
                                    <ng-container *ngFor="let childOfChild of child.children">
                                        <ng-container *ngIf="hasAccess(childOfChild.right)">
                                            <li nz-menu-item routerLinkActive="active">
                                                <ng-template
                                                    *ngTemplateOutlet="childTitleTpl; context: { child: childOfChild}"></ng-template>
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
            <ng-template #titleTpl>
                <div class="flex items-center py-4">
                    <span>{{item?.title}}</span>
                    <span *ngIf="item?.children" class="material-icons ml-2">keyboard_arrow_down</span>
                </div>
            </ng-template>
        </ng-container>
        <app-client-profile-menu [clientName]="(clientName$ | async) || ''" [isPostLoginMenu]="true" (logoutEvent)="logout()"></app-client-profile-menu>
        <app-header-notification></app-header-notification>
    </ul>

    <ng-template #childTitleTpl let-child="child">
        <a [routerLink]="child?.url? child.url : null">
            <span> {{ child?.title }} <span *ngIf="child.pendingNumber">({{ child.pendingNumber }})</span></span>
        </a>
    </ng-template>
</ng-template>

<app-mobile-menu-drawer
    [drawerVisible]="drawerVisible"
    [isAuthenticated]="true"
    [clientName]="(clientName$ | async)"
    [mobilePostNavData]="mobilePostNavData"
    (closeDrawer)="closeDrawer()"
    (logoutEvent)="logout()"
></app-mobile-menu-drawer>
