<div>
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="notificationTypeEnum.TRANSACTION">
            <span nz-icon nzType="bell" nzTheme="outline" class="text-primary text-[24px]"></span>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span nz-icon nzType="bell" nzTheme="outline" class="text-primary text-[20px]"></span>
        </ng-container>
    </ng-container>
</div>
