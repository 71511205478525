import {EventEmitter, Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    pendingOrderCounts: number = 0;
    processingOrderCounts: number = 0;
    hasUnreadNotification = false;

    pendingOrderUpdateEvent: EventEmitter<number> = new EventEmitter<number>();
    processingOrderUpdateEvent: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    updatePendingOrders(counts: number) {
        this.pendingOrderCounts = counts;
        this.pendingOrderUpdateEvent.emit(counts);
    }

    updateProcessingOrders(counts: number) {
        this.processingOrderCounts = counts;
        this.processingOrderUpdateEvent.emit(counts);
    }

    updateNotification(unread: boolean) {
        this.hasUnreadNotification = unread;
    }

}
