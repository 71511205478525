export class RouteConstant {
    // Developer
    public static readonly DEVELOPER: string = 'dev';
    public static readonly DEVELOPER_REVAMP: string = 'developer';
    public static readonly STYLE_LANGUAGE: string = 'style-language';
    public static readonly BASIC_COMPONENTS: string = 'basic-components';
    public static readonly UI_COMPONENTS: string = 'ui-components';
    public static readonly STYLE_GUIDE: string = 'style-guide';

    // Basic
    public static readonly _SLASH: string = '/';
    public static readonly EDIT: string = 'edit';

    // context
    public static readonly CONTEXT: string = 'ifast-fm';

    // Pre Login
    public static readonly HOME: string = 'home';
    public static readonly OUR_FUNDS: string = 'our-funds';
    public static readonly ABOUT_US: string = 'about-us';
    public static readonly MEDIA: string = 'media';
    public static readonly INSIGHTS: string = 'insights';
    public static readonly FAQ: string = 'faq';
    public static readonly PARTNERSHIP: string = 'partnership';
    public static readonly CONTACT_US: string = 'contact-us';
    public static readonly PLACEHOLDER: string = 'not-found';
    public static readonly ALL_DIST: string = 'distributors';
    public static readonly DASHBOARD: string = 'dashboard';
    public static readonly ARTICLES: string = 'articles';
    public static readonly VIDEOS: string = 'videos';
    public static readonly ARTICLE_DETAIL: string = 'article-detail';

    // Our Funds
    public static readonly OVERVIEW: string = 'overview';
    public static readonly FACTSHEET: string = 'factsheet';

    // Others
    public static readonly PRIVACY: string = 'privacy-policy';
    public static readonly DISCLAIMER: string = 'disclaimer';
    public static readonly TERMS: string = 'terms-condition';
    public static readonly CAREER: string = 'career';
    public static readonly BEST_EXECUTION_POLICY: string = 'best-execution-policy';

    // auth
    public static readonly LOGIN: string = 'login';

    // portfolio management
    public static readonly PORTFOLIO_MANAGEMENT: string = 'portfolio-management';
    public static readonly PORTFOLIO_TRANSACTION: string = 'portfolio-transaction';
    public static readonly ORDER_PAD: string = 'order-pad';
    public static readonly ORDER_PAD_SUMMARY: string = 'order-pad-summary';
    public static readonly PORTFOLIO_HOLDINGS: string = 'portfolio-holdings';
    public static readonly CASH_MANAGEMENT: string = 'cash-management';
    public static readonly FUND_PROFILE: string = 'fund-profile';
    public static readonly HISTORICAL_ORDERS: string = "historical-order";

    // approval
    public static readonly APPROVALS: string = 'approvals';
    public static readonly MY_APPROVALS: string = 'my-approvals';
    public static readonly PROCESSING: string = 'processing';
    public static readonly HISTORICAL: string = 'historical';
    public static readonly REJECTED_VOIDED: string = 'rejected-voided';
    public static readonly PENDING_ORDERS: string = 'pending-orders';
    public static readonly ORDER_APPROVAL: string = 'order-approval';
    public static readonly LIVE_ORDER_APPROVAL: string = 'live-order-approval';
    public static readonly ORDER: string = 'order';
    public static readonly LIVE_ORDER: string = 'live-order';
    public static readonly AMENDMENT: string = 'amendment';
    public static readonly REPORT: string = 'report';
    public static readonly NOTIFICATION: string = 'notification';

    // account settings
    public static readonly PROFILE_SETTINGS: string = 'profile-settings';
    public static readonly MY_PROFILE: string = 'my-profile';
    public static readonly CHANGE_PASSWORD: string = 'change-password';

}
