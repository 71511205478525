<div class="ui-empty-box" [ngClass]="cssClass">
    <ng-container [ngSwitch]="design">
        <ng-container *ngSwitchDefault>
            <img class="mb-6" [src]="imagePath + 'empty-box.png'" alt="IFAST FM"/>
            <p *ngIf="title" class="mb-0 text-base text-grey font-semibold">{{ title }}</p>
            <p *ngIf="description" class="mb-0 text-grey text-sm">{{description}}</p>
        </ng-container>
        <ng-container *ngSwitchCase="1">
            <img class="mb-6" [src]="imagePath + 'empty-box-1.svg'" alt="IFAST FM"/>
            <p *ngIf="title" class="mb-0 text-base font-semibold">{{ title }}</p>
            <p *ngIf="description" class="mb-0 text-sm">{{description}}</p>
        </ng-container>
        <ng-container *ngSwitchCase="2">
            <img class="mb-6" [src]="imagePath + 'chart-center-empty-state.svg'" alt="IFAST FM"/>
            <p *ngIf="title" class="mb-0 text-base font-semibold">{{ title }}</p>
            <p *ngIf="description" class="mb-0 text-sm">{{description}}</p>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <img class="mb-6" [src]="imagePath + 'empty-box-2.svg'" alt="IFAST FM"/>
            <p *ngIf="title" class="mb-0 text-base font-semibold">{{ title }}</p>
            <p *ngIf="description" class="mb-0 text-sm">{{description}}</p>
        </ng-container>
    </ng-container>
</div>
