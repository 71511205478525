<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" [nzPlacement]="'right'" (nzOnClose)="onClose()"
           [nzWidth]="'75%'"
           [nzFooter]="mobileMenuDrawerFooter"
           nzWrapClassName="mobile-menu-drawer"
>
    <ng-container *nzDrawerContent>
        <ng-container *ngIf="isAuthenticated">
            <div class="flex justify-between items-center">
                <div class="flex items-center">
                    <nz-avatar [nzText]="clientName[0]" class="avatar"></nz-avatar>
                    <span class="client-name">{{ clientName || '-' }}</span>
                </div>
                <app-header-notification (click)="onClose()"></app-header-notification>
            </div>
        </ng-container>
        <nav class="mobile-nav">
            <ng-container *ngIf="isAuthenticated">
                <a (click)="redirectToDestination(RouteConstant?.PROFILE_SETTINGS)">
                    <i nz-icon nzType="user" class="mr-2"></i>Profile Settings
                </a>
                <a (click)="redirectToDestination(RouteConstant?.CONTACT_US)">
                    <i nz-icon nzType="contacts" class="mr-2"></i>Contact Us
                </a>
                <a (click)="redirectToDestination(RouteConstant?.HOME)">
                    <i nz-icon nzType="home" class="mr-2"></i>Home
                </a>
                <a (click)="redirectToDestination(RouteConstant?.ABOUT_US)">
                    <i nz-icon nzType="info-circle" class="mr-2"></i>About Us
                </a>
                <a (click)="redirectToDestination(RouteConstant?.OUR_FUNDS)">
                    <i nz-icon nzType="fund" class="mr-2"></i>Our Funds
                </a>
                <a (click)="redirectToDestination(RouteConstant?.MEDIA)">
                    <i nz-icon nzType="read" class="mr-2"></i>Media
                </a>
                <nz-divider></nz-divider>
            </ng-container>

            <ng-container *ngIf="!isAuthenticated">
                <a (click)="redirectToDestination(RouteConstant?.HOME)">
                    <i nz-icon nzType="home" class="mr-2"></i>Home
                </a>
                <nz-divider></nz-divider>
                <a (click)="redirectToDestination(RouteConstant?.ABOUT_US)">
                    <i nz-icon nzType="info-circle" class="mr-2"></i>About Us
                </a>
                <nz-divider></nz-divider>
                <a (click)="redirectToDestination(RouteConstant?.OUR_FUNDS)">
                    <i nz-icon nzType="fund" class="mr-2"></i>Our Funds
                </a>
                <nz-divider></nz-divider>
                <a (click)="redirectToDestination(RouteConstant?.MEDIA)">
                    <i nz-icon nzType="read" class="mr-2"></i>Media
                </a>
                <nz-divider></nz-divider>
                <a (click)="redirectToDestination(RouteConstant?.PARTNERSHIP)">
                    <i nz-icon nzType="read" class="mr-2"></i>Partnership
                </a>
                <nz-divider></nz-divider>
                <a (click)="redirectToDestination(RouteConstant?.CONTACT_US)">
                    <i nz-icon nzType="contacts" class="mr-2"></i>Contact Us
                </a>
            </ng-container>
            <ng-container *ngIf="isAuthenticated">
                <ng-container *ngFor="let item of mobilePostNavData; let l=last">
                    <ng-container *ngIf="hasAccess(item.right)">
                        <ng-container *ngIf="!item.children">
                            <a (click)="redirectToDestination(item.url)">
                                <i nz-icon [nzType]="item.icon" class="mr-2"></i>{{ item.title }}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="item.children">
                                <span class="mobile-parent">
                                    <i nz-icon [nzType]="item.icon" class="mr-2"></i>{{ item.title }}
                                </span>
                            <ng-container *ngFor="let child of item.children">
                                <ng-container *ngIf="hasAccess(child.right)">
                                    <a class="mobile-children" (click)="redirectToDestination(child.url)">
                                        <span>
                                            {{ child.title }} <span *ngIf="child.pendingNumber"> ({{ child.pendingNumber }})</span>
                                        </span>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <nz-divider *ngIf="!l"></nz-divider>
                    </ng-container>
                </ng-container>
            </ng-container>
        </nav>
    </ng-container>
    <ng-template #mobileMenuDrawerFooter>
        <div class="text-right">
            <button *ngIf="!isAuthenticated" nz-button nzType="primary" (click)="redirectToDestination(RouteConstant?.LOGIN)">Login</button>
            <button *ngIf="isAuthenticated" nz-button nzType="primary" (click)="logout()">Logout</button>
        </div>
    </ng-template>
</nz-drawer>
