import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-ui-empty-box',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './ui-empty-box.component.html',
    styleUrls: ['./ui-empty-box.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class UiEmptyBoxComponent implements OnInit {

    @Input() title!: string;
    @Input() imagePath = 'assets/images/icon/empty/';
    @Input() cssClass = '';
    @Input() design: number = 0;
    @Input() description!: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
